
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Files extends Vue {
  protected name = "Files";

  @Prop({ required: true })
  public files!: Array<string>;

  protected onFileClicked(fullPath: string) {
    this.$emit("show-file", fullPath);
  }
}
