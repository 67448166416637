
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import SslCertForm from "@/views/SslCerts/sslCerts/SslCertForm.vue";
import AppConfig from "@/config/AppConfig";
import Files from "@/components/files/Files.vue";

const SslCertModule = namespace(AppConfig.SSL_CERTIFICATE_STORE);

@Component({
  components: { Files, SslCertForm },
  mixins: [UpdateMixin],
})
export default class SslCertUpdate extends Vue {
  protected name = "SslCertUpdate";

  @SslCertModule.Action("findOne")
  protected loadItemAction: any;

  @SslCertModule.Action("findAllSubresource")
  protected loadSubresourceAction: any;

  @SslCertModule.Action("update")
  protected updateItemAction: any;

  @SslCertModule.Getter("getDataItem")
  protected getItem: any;

  @SslCertModule.Getter("getIsLoading")
  protected isLoading: any;

  @SslCertModule.Getter("getFiles")
  protected getFiles: any;

  @SslCertModule.Getter("getFile")
  protected getFile: any;

  protected sslCertDateFormat = "YYMMDDHHmmss[Z]";

  @Watch("getItem")
  protected onItemChanged(item: any) {
    if (item) {
      this.loadFiles();
    }
  }

  protected init(): void {
    this.loadItemAction({ id: this.$route.params.id, resource: this.resource });
  }

  public get resource(): string {
    return AppConfig.SSL_CERTIFICATE_RESOURCE;
  }

  public get descriptionField(): string {
    return "domain_id";
  }

  protected loadFiles(): void {
    if (this.getItem) {
      this.loadSubresourceAction({
        mainResource: this.resource,
        id: this.getItem.id,
        resource: "files",
      });
    }
  }

  protected onShowFile(file: string): void {
    console.log("onShowFiles", file);
    this.loadSubresourceAction({
      mainResource: this.resource,
      id: this.getItem.id,
      resource: "file",
      extraQueryParams: { file },
    });
  }

  protected getRenderedFile(file: string) {
    return file.replace(/\n/g, "<br/>");
  }
}
