
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import ISslCertificate from "@/interfaces/ISslCertificate";
import AppConfig from "@/config/AppConfig";

const SslCertModule = namespace(AppConfig.SSL_CERTIFICATE_STORE);

@Component({
  components: { DefaultForm },
  mixins: [FormMixin],
})
export default class SslCertForm extends Vue {
  protected name = "SslCertForm";

  @SslCertModule.Action("load")
  protected loadSslCertsAction: any;

  @SslCertModule.Getter("getIsLoading")
  protected isLoading: any;

  @SslCertModule.Getter("getIsSaving")
  protected isSaving: any;

  @SslCertModule.Getter("getValidationErrors")
  protected getValidationErrors: any;

  @SslCertModule.Getter("getError")
  protected getError: any;

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: ISslCertificate;

  protected id?: number;
  protected autoRenewal = false;
  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;
  protected lastCheckAt?: Date | string | null;
  protected lastValidSeenAt?: Date | string | null;
  protected validUntil: Date | string | null = null;
  protected sslCertificate: any = {};
  protected rawSslCertificateFields: any = {};
  protected domainId = -1;
  protected rawSslCertificate = null;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: ISslCertificate) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): ISslCertificate {
    let sslCert: ISslCertificate = {
      valid_until: this.validUntil,
      last_check_at: this.lastCheckAt ?? "-",
      last_valid_seen_at: this.lastValidSeenAt ?? "-",
      domain_id: this.domainId,
      raw_ssl_certificate: this.rawSslCertificate,
      raw_ssl_certificate_fields: this.rawSslCertificateFields,
    };
    if (this.data && this.data.id) {
      sslCert.id = this.id;
    }
    return sslCert;
  }

  protected setFormValues(data: ISslCertificate): void {
    console.log("set form values ..", data);
    if (data) {
      this.id = data.id;
      this.validUntil = data.valid_until;
      this.lastCheckAt = data.last_check_at;
      this.lastValidSeenAt = data.last_valid_seen_at;
      this.domainId = data.domain_id;
      this.rawSslCertificate = data.raw_ssl_certificate;
      this.rawSslCertificateFields = data.raw_ssl_certificate_fields;
    }
  }

  protected init() {}
}
