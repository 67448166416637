var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.isSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('general.url')),"type":_vm.getValidationErrors && _vm.getValidationErrors.url
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.url
                  ? _vm.getValidationErrors.url[0]
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.url')),"required":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.ssl_certs.contact_email')),"type":_vm.getValidationErrors && _vm.getValidationErrors.contact_email
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.contact_email
                  ? _vm.getValidationErrors.contact_email[0]
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.email')),"required":""},model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(_vm.$t('modules.ssl_certs.auto_renewal')),"type":_vm.getValidationErrors && _vm.getValidationErrors.auto_renewal
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.auto_renewal
                  ? _vm.getValidationErrors.auto_renewal[0]
                  : ''}},[_c('b-switch',{attrs:{"required":""},model:{value:(_vm.autoRenewal),callback:function ($$v) {_vm.autoRenewal=$$v},expression:"autoRenewal"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }